import React, { useState } from "react";
import styled from "styled-components";
import brandLight from './logo.png';
import brandDark from './logo.png';
import brandDarkFooter from './logo.png';
import useEffectScroll from "../../hooks/useEffectScroll";

const MainBrand = styled.div`
   img{
    width : ${props => props.width};
    max-width: 100%;
    height: ${props => props.height};
   }
   .v-light & , & {
        .logo-dark{
           display : block ;     
        }
        .logo-light{
            display : none;    
        }
   }
    
   .v-dark & {
       .logo-dark{
           display : none ;     
        }
        .logo-light{
            display : block;    
        }
   }
   
`;

const Brand = ({ width, height, alt, isFooter = false, isOpenMenu = false }) => {

    const [isScrollDown, setScrollDown] = useState(false);
    let scrDown = 0;
    useEffectScroll((e, x, y) => {
        if (y > 170) {
            if (scrDown < y) {
            } else {
                setScrollDown(true);
            }
        } else {
            setScrollDown(false);
        }

        scrDown = y;
    });

    return (
        <MainBrand className="main-brand" width={width} height={height}>
            <img className="logo-dark" src={isFooter ? brandDarkFooter : brandLight} alt={`${alt} - logo light`} width={width} height={height} />
            <img className="logo-light" src={(isFooter || isOpenMenu || isScrollDown) ? brandDarkFooter : brandDark} alt={`${alt} - logo dark`} width={width} height={height} />
        </MainBrand>
    );
}

MainBrand.defaultProps = { width: '80px', height: 'auto' }
Brand.defaultProps = { alt: "Keshvi Ceramic" }

export default React.memo(Brand);