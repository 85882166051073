import React, { useState, useEffect } from 'react';
import { dsnCN } from "../../../hooks/helper";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedin, faPinterest, faYoutube, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import axios from 'axios';
import { API_URL } from '../../../shared/constant';

function MenuContent({ className }) {
    const [contact, setContact] = useState({});

    useEffect(() => {
        axios.post(API_URL.SITE_CONTACT_INFO, {}).then(res => {
            if (res.data.data) {
                setContact(res.data.data);
            }
        })
    }, []);

    const socialData = [
        { link: contact.facebook, icon: faFacebookF },
        { link: contact.twitter, icon: faTwitter },
        { link: contact.instagram, icon: faInstagram },
        { link: contact.linkedin, icon: faLinkedin },
        { link: contact.pinterest, icon: faPinterest },
        { link: contact.youtube, icon: faYoutube },
    ];

    let wpn = contact?.extra_mobile_number?.replace(/ /g, "");
    let cn = contact?.mobile_number?.replace(/ /g, "");
    let number = "918460239675"
    let displayNumber = "+91 8460239675"

    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Adress</h5>
                    <p style={{ color: 'white' }}>
                        {contact.address_one} <br />
                        {contact.address_two}<br />
                    </p>

                    {contact.office_address_title ? <>
                        <h4 className="footer-title mt-3">{contact.office_address_title}</h4>
                        <p style={{ color: 'white' }}>
                            {contact.office_address_one} <br />
                            {contact.office_address_two} <br />
                        </p></> : null}

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden mb-1">
                        <a href={'https://wa.me/' + cn} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: 16 }}><FontAwesomeIcon icon={faWhatsapp} /> {contact.mobile_number}</a>
                    </p>
                    <p className="links over-hidden mb-1">
                        <a href={'https://wa.me/' + wpn} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: 16 }}><FontAwesomeIcon icon={faWhatsapp} /> {contact.extra_mobile_number}</a>
                    </p>
                    <p className="links over-hidden mb-1">
                        <a href={'https://wa.me/' + number} target='_blank' rel="noreferrer" style={{ textDecoration: 'none', fontSize: 16 }}><FontAwesomeIcon icon={faWhatsapp} /> {displayNumber}</a>
                    </p>
                    <p className="links over-hidden">
                        <p><a href={'mailto:' + contact.contact_email} target='_blank' rel="noreferrer" style={{ textDecoration: 'none' }}> {contact.contact_email}</a></p>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block">Follow us</h5>
                    <ul style={{ paddingLeft: '0px', marginTop: '0px', marginBottom: '0px' }} className={dsnCN('social-one', className)}>
                        {socialData.map((item, index) =>
                            item.link ?
                                <li key={index}>
                                    <a href={item.link} target="_blank" rel="noopener noreferrer">
                                        <FontAwesomeIcon icon={item.icon} />
                                    </a>
                                </li> : ''
                        )}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;